import https from "./https";
import store from "@/store";

const groupbuy = {
  getGroupBuyProducts(code, branchID) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams({
      code,
      branch_id: branchID,
    });
    return https.get(
      `admin/organizations/${organization.id}/groupbuying-products?${searchParams}`
    );
  },

  updateForMainStore(mainGroupbuyingId, params) {
    const organization = store.state.general.organization;
    return https.put(`admin/organizations/${organization.id}/main-store-groupbuyings/${mainGroupbuyingId}`, {
      ...params,
    });
  },

  createForMainStore(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/main-store-groupbuyings`, {
      ...params,
    });
  },

  startForMainStore(id) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/main-store-groupbuyings/${id}/start`);
  },

  uploadBranchesByExcel(formData) {
    const organization = store.state.general.organization;
    const url = `admin/organizations/${organization.id}/main-store-groupbuyings/action/upload-branches-by-excel`;

    return https.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  getStoreGroupBuyProducts(skuSerialNumber, branchID) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams({
      sku_serial_number: skuSerialNumber,
      branch_id: branchID,
    });
    return https.get(
      `admin/organizations/${organization.id}/store-groupbuying-products?${searchParams}`
    );
  },

  syncStoreGroupBuyProducts(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/store-groupbuying-products`, params);
  },

  syncMainStoreGroupBuyProducts(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/main-store-groupbuying-products`, params);
  },

  getMainStoreGroupBuyProduct(skuSerialNumber) {
    const searchParams = new URLSearchParams({
      sku_serial_number: skuSerialNumber,
    });
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/main-store-groupbuying-product?${searchParams}`);
  },

  getGroupBuyEventProducts(params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams({
      ...params
    });
    return https.get(
      `admin/organizations/${organization.id}/groupbuyings-event-products?${searchParams}`
    );
  },

  getGroupbuyList(params) {
    const organization = store.state.general.organization;
    // const searchParams = new URLSearchParams(params); // 搜尋type要用[] 不然後端不過

    return https.get(`admin/organizations/${organization.id}/groupbuyings`, {params});
  },

  createGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/groupbuyings`, {
      ...params,
    });
  },

  createDraftGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/draft-groupbuyings`, {
      ...params,
    });
  },

  updateDraftGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.put(`admin/organizations/${organization.id}/draft-groupbuyings`, {
      ...params,
    });
  },

  checkBeforeStart(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/draft-groupbuyings/check-before-start?`, {
      ...params,
    });
  },

  startGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/draft-groupbuyings/start`, {
      ...params,
    });
  },

  getGroupbuying(groupbuying) {
    const organization = store.state.general.organization;
    return https.get(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}`
    );
  },

  getGroupbuyingOrders(groupbuying, params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams({
      ...params,
    });
    return https.get(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}/groupbuying-orders?${searchParams}`
    );
  },

  getPoyaGroupbuyingOrders(params) {
    const organization = store.state.general.organization;
    const searchParams = new URLSearchParams({
      ...params,
    });
    return https.get(
      `admin/poya/organizations/${organization.id}/groupbuying-orders?${searchParams}`
    );
  },

  cancelPoyaGroupbuyingOrder(id) {
    const organization = store.state.general.organization;
    return https.delete(
      `admin/poya/organizations/${organization.id}/groupbuying-orders/${id}`
    );
  },

  updatePoyaGroupbuyingOrder(id, params) {
    const organization = store.state.general.organization;
    return https.put(
      `admin/poya/organizations/${organization.id}/groupbuying-orders/${id}`,
      {
        ...params,
      }
    );
  },

  updateGroupbuying(groupbuying, params) {
    const organization = store.state.general.organization;

    return https.put(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}`,
      {
        ...params,
      }
    );
  },

  deleteGroupbuying(groupbuying) {
    const organization = store.state.general.organization;

    return https.delete(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}`
    );
  },

  deleteGroupbuyingsByProductForce(productId) {
    const organization = store.state.general.organization;

    return https.delete(
      `admin/organizations/${organization.id}/groupbuyings-by-product/${productId}/force`,
    );
  },

  updateGroupbuyingOrder(groupbuying, groupbuyingOrder, params) {
    const organization = store.state.general.organization;
    return https.put(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}/groupbuying-orders/${groupbuyingOrder}`,
      {
        ...params,
      }
    );
  },

  cancelGroupbuyingOrder(groupbuying, groupbuyingOrder) {
    const organization = store.state.general.organization;
    return https.delete(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}/groupbuying-orders/${groupbuyingOrder}`
    );
  },

  updateGroupbuyingStatus(groupbuying, action) {
    const organization = store.state.general.organization;
    return https.put(
      `admin/organizations/${organization.id}/groupbuyings/${groupbuying}/action/${action}`
    );
  },

  getGroupbuyingConfig() {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/groupbuying-config`)
  },

  // Core related
  getCoreGroupbuyList(params) {
    const organization = store.state.general.organization;
    return https.get(`admin/organizations/${organization.id}/core-groupbuyings`, {params});
  },

  getCoreGroupbuying(groupbuying) {
    const organization = store.state.general.organization;
    return https.get(
      `admin/organizations/${organization.id}/core-groupbuyings/${groupbuying}`
    )
  },


  coreCreateGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/core-groupbuyings`, {
      ...params,
    });
  },

  coreDeleteGroupbuying(groupbuying) {
    const organization = store.state.general.organization;

    return https.delete(
      `admin/organizations/${organization.id}/core-groupbuyings/${groupbuying}`
    );
  },

  coreUpdateGroupbuying(groupbuying, params) {
    const organization = store.state.general.organization;

    return https.put(
      `admin/organizations/${organization.id}/core-groupbuyings/${groupbuying}`,
      {
        ...params,
      }
    );
  },

  getAllSkus() {
    const organization = store.state.general.organization;

    return https.get(
      `admin/organizations/${organization.id}/product-skus?all=true`
    );
  },
  getSkus(params) {
    const organization = store.state.general.organization
    return https.get(`admin/organizations/${organization.id}/product-skus`, {params})
  },

  bindSku(groupbuyingId, params) {
    const organization = store.state.general.organization;
    return https.put(
      `admin/organizations/${organization.id}/core-groupbuyings/${groupbuyingId}/action/bind-product-sku`, {
      ...params,
    });
  },

  startCoreGroupbuy(params) {
    const organization = store.state.general.organization;
    return https.post(`admin/organizations/${organization.id}/core-groupbuyings/start`, {
      ...params,
    });
  },

};

export default groupbuy;
